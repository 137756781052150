<div class="block-traite">
    <h5>{{listLots.length}} résultats</h5>
    <div *ngIf="error" class="error">
        Erreur lors du chargement des données
    </div>
    <div class="traite" *ngFor="let elm of listLots">
        <div class="resume">
            <div class="title">
                LOT {{elm.id}}
                <div>DATE TRAITEMENT:  {{elm.date_traitement | date: 'dd-MM-yyyy'}}</div>
        </div>
        <div class="data">
            <div class="elmt">
                <div>instance</div>
                <div class="val">{{GN_REGIONS[elm.region]}}</div>
            </div>
            <div class="elmt ssj">
                <div>SSJ</div>
                <div class="val">{{elm?.fichier_traites?.length > 0 ? elm?.fichier_traites[0]?.ssj : '000'}}</div>
            </div>
            <div class="elmt flux">
                <div>T. flux</div>
                <div class="val">{{elm.flux}}</div>
            </div>
            <div class="elmt" *ngIf="flux != 'relance'">
                <div>{{elm.fichier_traites?.length > 1 ? 'Traités': 'Traité'}}</div>
                <div class="val">{{elm.fichier_traites?.length || 0}}</div>
            </div>
            <div class="elmt icon" *ngIf="false">
                <mat-icon *ngIf="elm.fichier_traites?.length">expand_more</mat-icon>
            </div>
        </div>
        </div>
        <div class="detail">
            <div class="stats-data">
                <div class="stats-item">
                    <div class="title">Attendues</div>
                    <div class="data">{{elm.flux != 'relance' ? elm.nombre_communication_precompose : '-'}}</div>
                </div>
                <div class="stats-item">
                    <div class="title">Pré-Composées</div>
                    <div class="data">{{elm.flux != 'relance' ? elm.nombre_communication_precompose : '-'}}</div>
                </div>
                <div class="stats-item">
                    <div class="title">Composées</div>
                    <div class="data">{{ elm.nombre_communication_compose}}</div>
                </div>
                <div class="stats-item">
                    <div class="title">Archivées</div>
                    <div class="data">{{ elm.nombre_communication_archive}}</div>
                </div>
                <div class="stats-item">
                    <div class="title">Envoyées</div>
                    <div class="data">{{ elm.nombre_communication_envoye}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex">
        <button mat-stroked-button class="btn-more" (click)="load()" *ngIf="!loading && loadMore">
            Charger plus...
        </button>
    </div>
</div>
<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
<br/>
<br/>
<br/>
<br/>
<br/>
