import { Router } from '@angular/router';
import { Component, OnInit, NgZone } from '@angular/core';
import {UserLoginService} from "../../services/user-login.service";


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  public loading: boolean = false;

  constructor(private router: Router, private userLoginService: UserLoginService, private zone: NgZone) {
    this.userLoginService.error.subscribe((message) => {
        console.error('message error', message);
        this.zone.run(() => {
            this.messageError = "Vous n'avez pas les droits nécessaires à l'utilisation de Shadok, merci de faire une demande via Helia";
        });
    });
    this.userLoginService.loginStatusChanged.subscribe((isLoading: boolean) => {
        this.zone.run(() => {
            this.loading = isLoading;
        });
    });  
  }

  messageError = '';

  ngOnInit() {
      this.userLoginService.isAuthenticated(this);
  }


  isLoggedIn(message: string, isLoggedIn: boolean) {
      if (isLoggedIn) {
          this.router.navigate(['/monitoring/prod/home']);
      }
  }

}
