import { KeycloakAuthService } from 'src/app/services/keycloak-auth.service';

export const environment = {
  production: false,
  environment: 'DEV',
  apiURL: "https://mfq1x2yt4k.execute-api.eu-west-1.amazonaws.com/dev",
  awsRegion: "eu-west-1",
  googleClientId: "290638486407-eoff3e5psd3v41mk7ofg6iebndvu8nue.apps.googleusercontent.com",
  identityPoolId: "eu-west-1:61ddaf55-6630-4871-8b8a-d10ec1e16952",
  build: "294",
  commit: "54c04eac25e1750b775e7504c88e46121c6cc628",

  auth: {
    config: {
      config: {
        url: "https://keycloak-hom.dir.istefr.fr",
        realm: "vef",
        clientId: "SHADOK",
      },
      initOptions: {
        onLoad: "check-sso",
        flow: "standard",
        "enable-cors": true,
        pkceMethod: "S256",
        silentCheckSsoRedirectUri:
          "https://dev-shadok.dir.istefr.fr/assets/silent-check-sso.html",
        "public-client": true,
      },
    },
    loginRedirectUri: "https://dev-shadok.dir.istefr.fr/monitoring/prod/home",
    logoutRedirectUri: "https://dev-shadok.dir.istefr.fr/",
    provider: KeycloakAuthService,
    federatedSignInProvider: "keycloak-hom.dir.istefr.fr/realms/vef",
  }
};
