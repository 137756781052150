<div class="header">
    <div>
        <h5>Recherche avancée</h5>
        <label>< Recherche par traité</label>
    </div>
    <mat-icon  [mat-dialog-close]="true">highlight_off</mat-icon>
</div>
<div class="content">
    <form class="date">
        <mat-label> {{flux == "lot" ?  "Date de traitement" : "Date d'émission" }}</mat-label>
        <mat-form-field appearance="fill">
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate readonly placeholder="Date début" [formControl]="dateGte">
                <input matEndDate readonly placeholder="Date fin" [formControl]="dateLte">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </form>
    <form *ngIf="flux !== 'lot' && flux !== 'relance'">
        <mat-label>Code de facturation</mat-label>
        <mat-form-field appearance="fill">
            <input matInput [formControl]="code"/>
        </mat-form-field>
    </form>

    <div class="button-group">
        <button mat-stroked-button class="btn-next" (click)="valid()" >
            Valider
        </button>
        <button mat-raised-button class="btn-previuos" (click)="cancel()"  [mat-dialog-close]="true">
            Annuler
        </button>
    </div>
</div>
