<div class="block-traite" *ngIf="list.length || listES.length">
    <h5>{{(version1 ? list.length: listES.length)}} résultats</h5>
    <div *ngIf="error" class="error">
        Erreur lors du chargement des données
    </div>
    <div class="traite" *ngFor="let elm of (version1 ? list: listES); let i = index" [ngClass]="{'last': (loadMore && !version1 && i == listES.length - 1) }">
        <div class="resume">
            <div class="title">
                {{version1 ? ('Traité ' + elm.traite) : ( elm.type_facturation + ' - '+ elm.traites.length + ' Traité(s)')}}
            - Date d'émission {{elm.date_emission  | date: 'dd-MM-yyyy' }}
        </div>
        <div class="data">
            <div class="instance">
                <div>instance</div>
                <div class="val">{{elm.instance}}</div>
            </div>
            <div class="ssj">
                <div>SSJ</div>
                <div class="val">{{elm.ssj || '000'}}</div>
            </div>
            <div class="flux">
                <div>T. flux</div>
                <div class="val">{{elm.flux}}</div>
            </div>
            <div class="number">
                <div>Numero de lot</div>
                <div class="val">{{elm.fichier_id}}</div>
            </div>
        </div>
        </div>
        <div class="detail">
            <div class="stats-data">
                <div class="stats-item">
                    <div class="title">Attendues</div>
                    <div class="data">{{elm.nombre_communication_precompose}}</div>
                </div>
                <div class="stats-item" [ngClass]="getClass(elm, 'precompose', 'compose')">
                    <div class="title">Pré-Composées</div>
                    <div class="data">{{elm.nombre_communication_precompose}}</div>
                </div>
                <div class="stats-item" [ngClass]="getClass(elm, 'compose', 'archive')">
                    <div class="title">Composées</div>
                    <div class="data">{{elm.nombre_communication_compose}}</div>
                </div>
                <div class="stats-item" [ngClass]="getClass(elm, 'archive', 'envoye')">
                    <div class="title">Archivées</div>
                    <div class="data">{{elm.nombre_communication_archive}}</div>
                </div>
                <div class="stats-item" [ngClass]="getClass(elm, 'envoye', '')">
                    <div class="title">Envoyées</div>
                    <div class="data">{{elm.nombre_communication_envoye}}</div>
                </div>
            </div>
            <div [ngClass]="{'full' : elm.nombre_communication_envoye != 0}">
                <button mat-stroked-button class="btn" (click)="getReport(elm)">
                    Détails
                    </button>
            </div>
        </div>
    </div>
    <div class="flex">
        <button mat-stroked-button class="btn-more" (click)="load()" *ngIf="!loading && loadMore">
            Charger plus...
        </button>
    </div>
</div>
<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
