<div>
    <div class="number border-bottom-gray">
        <b> {{data.total}}</b>
        <mat-icon aria-hidden="false" class="icon-error" *ngIf="data.error">warning</mat-icon>
        <mat-icon aria-hidden="false">zoom_out</mat-icon>
    </div>
    <div class="row">
        <div class="key">
            <div matTooltip="Courrier">
                C
            </div>
            <mat-icon aria-hidden="false" class="icon-error" *ngIf="data.detail['C'].error">warning</mat-icon>
        </div>
        <div class="value">{{data.detail['C'].val}}</div>
    </div>
    <div class="row">
        <div class="key">
            <div matTooltip="Démat Particulier">
                D
            </div>
            <mat-icon aria-hidden="false" class="icon-error" *ngIf="data.detail['D'].error">warning</mat-icon>
        </div>
        <div class="value">{{data.detail['D'].val}}</div>
    </div>
    <div class="row">
        <div class="key">
            <div matTooltip="Notification démat">
                Nm
            </div>
            <mat-icon aria-hidden="false" class="icon-error" *ngIf="data.detail['Nm'].error">warning</mat-icon>
        </div>
        <div class="value">{{data.detail['Nm'].val}}</div>
    </div>
    <div class="row">
        <div class="key">
            <div matTooltip="Démat Grands Comptes EDI">
                DGC EDI
            </div>
            <mat-icon aria-hidden="false" class="icon-error" *ngIf="data.detail['DGCedi'].error">warning</mat-icon>
        </div>
        <div class="value">{{data.detail['DGCedi'].val}}</div>
    </div>
    <div class="row">
        <div class="key">
            <div matTooltip="Démat Grands Comptes CHO">
                DGC CHO
            </div>
            <mat-icon aria-hidden="false" class="icon-error" *ngIf="data.detail['DGCcho'].error">warning</mat-icon>
        </div>
        <div class="value">{{data.detail['DGCcho'].val}}</div>
    </div>
</div>
