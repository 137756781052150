import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdComponent } from './pages/prod/prod.component';
import { MonitoringComponent } from './monitoring.component';
import { MonitoringRoutingModule } from './monitoring-routing.module';
import { MaterialModule } from '../material/material.module';
import { ReportMultipleComponent } from "./pages/report-multiple/report-multiple.component";
import { NumberDetailsComponent } from './components/number-details/number-details.component';
import { NgxVerticalTimelineModule } from 'ngx-vertical-timeline';
import { GroupByComponent } from './components/group-by/group-by.component';
import {ComponentsModule} from "../components/components.module";
import { TraiteListComponent } from './components/traite-list/traite-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FiltersAdvencedComponent } from './modals/filters-advenced/filters-advenced.component';
import { ReactiveFormsModule } from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [
    ProdComponent,
    ReportMultipleComponent,
    MonitoringComponent,
    NumberDetailsComponent,
    GroupByComponent,
    TraiteListComponent,
    FiltersAdvencedComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MonitoringRoutingModule,
    NgxVerticalTimelineModule,
    ComponentsModule,
    MatDialogModule,
    SharedModule,
    NgxPaginationModule
  ]
})

export class MonitoringModule { }
