import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { SimpleNotificationsModule } from 'angular2-notifications';

// Interceptor
import { HttpErrorInterceptor } from './services/http-error.interceptor';

// Modules
import { MaterialModule } from './material/material.module';
import { MonitoringModule } from './monitoring/monitoring.module';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RedirectionComponent } from './modal/redirection/redirection.component';
import { ComponentsModule } from "./components/components.module";
import {LandingComponent} from "./pages/landing/landing.component";
import { HomeComponent } from './pages/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { GoogleSigninBtnComponent} from './components/google-signin/google-signin-btn.component'
import { UserLoginService } from './services/user-login.service';

export function initializeAuthProvider(userService: UserLoginService): Function {
  return () => {
      userService.init();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    RedirectionComponent,
    LandingComponent,
    HomeComponent,
    
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MaterialModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ComponentsModule,
    MonitoringModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    DatePipe,
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
      { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
      { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
      { provide: APP_INITIALIZER, useFactory: initializeAuthProvider, multi: true, deps: [UserLoginService] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
