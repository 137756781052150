<div class="dialog">
    <div mat-dialog-content>
        <div class="content">

            <button mat-raised-button class="btn-secondary" [mat-dialog-close]="true" [routerLink]="['/monitoring']" >
                <mat-icon aria-hidden="false" aria-label="Example home icon">trending_up</mat-icon>
                Suivi de production
            </button>
            <button mat-stroked-button class="btn-secondary" [mat-dialog-close]="true" [routerLink]="['/monitoring/report']" >
                <mat-icon aria-hidden="false" aria-label="Example home icon">description</mat-icon>
                Rapports de composition
            </button>
            <button mat-stroked-button class="btn-secondary" [mat-dialog-close]="true" [routerLink]="['/communication']">
                <mat-icon aria-hidden="false" aria-label="Example home icon">post_add</mat-icon>
                Commmunication en lot
            </button>
        </div>
    </div>
</div>
