import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MonitoringComponent} from "./monitoring.component";
import {ProdComponent} from "./pages/prod/prod.component";
import {ReportMultipleComponent} from "./pages/report-multiple/report-multiple.component";
import { AuthGuard } from '../guards/auth-guard.service';

const monitoringRoutes: Routes = [
  {
    path: 'monitoring',
    component: MonitoringComponent,
    children: [
      {
        path: 'prod/:source',
        component: ProdComponent
      },
      {
        path: 'reportes',
        component: ReportMultipleComponent
      },
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(monitoringRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class MonitoringRoutingModule { }
