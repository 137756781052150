import { Observable,forkJoin  } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLS } from '../../../environments/urls';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

    constructor(
        private http: HttpClient
    ) { }
    public getFileTraites(ids): Observable<any> {
        return this.http.get<any>((environment.apiURL + '/' + URLS.monitoring.file_traite.get + '/detail'),
            {params :{ 'fichier-traite-id':  ids.join('File') }});
    }
    public postAction(body): Observable<any> {
        return this.http.post<any>(environment.apiURL + '/action' , body);
    }
    public getHabilitation(): Observable<any> {
        return this.http.get<any>((environment.apiURL + '/habila_permissions'));
    }
    public getFile(params): Observable<any> {
        return this.http.get<any>(environment.apiURL + '/' + URLS.monitoring.file.get , { params });
    }
    public addComment(body): Observable<any> {
        return this.http.post<any>(environment.apiURL + '/' + URLS.monitoring.comment.get , body);
    }

    public getFilesTraite(params: any): Observable<any> {
        localStorage.setItem('params', JSON.stringify(params));
        return this.http.get<any>(environment.apiURL + '/' + URLS.monitoring.file_traite.get, { params });
    }

    compose() {
        const body = {
            "canal": [
            {
            "principal": "M",
            "secours":["C","S"]
            }
            ],
            "fill-marque": true,
            "fill-contact": true,
            "fill-abo":true,
            "mode-interface": true,
            "statut-abonnement": "actif",
            "date-creation": "2023-0-14",
            "systeme-emetteur": "CLOT",
            "roles":[
            "abonne"
            ],
            "datamaster": "DataMaster_Lot_Generique.wfd",
            "bucket-fichier": "s3://veolia-osiris-echange-dev.s3.amazonaws.com/COMPOSITION_ENVOI/CLOT-20230314-7d81fe/CLOT-20230314-7d81fe.json"
            }
            return this.http.post<any>(environment.apiURL + '/' + URLS.monitoring.file.get , body);

      }

}
