import { Component, OnInit } from '@angular/core';
import {MonitoringService} from "../../services/monitoring.service";
import {MatDialog} from '@angular/material/dialog';
import { FiltersAdvencedComponent } from '../../modals/filters-advenced/filters-advenced.component';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { UserLoginService } from "../../../services/user-login.service";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-prod',
  templateUrl: './prod.component.html',
  styleUrls: ['./prod.component.scss']
})
export class ProdComponent implements OnInit {

    expandedRow = 0;
    loading = false;
    dateObj = new Date();
    params_tmp : any = JSON.parse(localStorage.getItem('search_params'));
    search_params : any = {
        'date-emission[gte]': this.params_tmp && this.params_tmp['date-emission[gte]'] ? this.params_tmp['date-emission[gte]'] : this.datePipe.transform(this.dateObj.setMonth(this.dateObj.getMonth() - 6), 'yyyy-MM-dd'),
        'date-emission[lte]': this.params_tmp && this.params_tmp['date-emission[lte]'] ? this.params_tmp['date-emission[lte]'] : '',
        code_facturation: this.params_tmp && this.params_tmp.code_facturation ? this.params_tmp.code_facturation : '',
        instance: this.params_tmp && this.params_tmp.instance ? this.params_tmp.instance : '',
        traite: this.params_tmp && this.params_tmp.traite ? this.params_tmp.traite : '',
        ssj: this.params_tmp && this.params_tmp.ssj ? this.params_tmp.ssj : '',
        flux: this.params_tmp && this.params_tmp.flux ? this.params_tmp.flux : 'es',
        flux_param: this.params_tmp && this.params_tmp.flux_param ? this.params_tmp.flux_param : 'es'
    }
    params = {
        'date-emission[gte]': this.search_params['date-emission[gte]'],
        'date-emission[lte]': this.search_params['date-emission[lte]'],
        region: '',
        code_facturation: this.search_params.code_facturation,
        instance: this.search_params.instance,
        traite: this.search_params.traite,
        status: '',
        ssj: this.search_params.ssj,
        flux: this.search_params.flux_param
    }
    files = [];
    items = [];
    traites = [];
    traitesV2 = [];
    lotOffset = '';
    traite = '';
    flux = this.search_params.flux;
    list = [ { key: 'factCycl', label: 'Facture cycle' }, { key: 'factES', label: 'Facture E/S' }, { key: 'PerCyc', label: 'Percepteur cycle' }, { key: 'PerES', label: 'Percepteur E/S' }, { key: 'relance', label: 'Relance' }, { key: 'echeancier', label: 'Echeancier' }];
    fluxKey = {
        factCycl: 'Facture cycle',
        factES: 'Facture E/S',
        PerCyc: 'Percepteur cycle',
        PerES: 'Percepteur E/S',
        relance: 'Relance',
        echeancier: 'Echeancier'
    }
    fluxEnum = {
        factCycl: 'facture cycle',
        factES: 'facture E/S',
        PerCyc: 'percepteur cycle',
        PerES: 'percepteur E/S',
        relance: 'relance',
        echeancier: 'Echeancier'
    }
    statusKey = {
        en_cours: 'en cours',
        terminer: 'terminer',
        erreur: 'erreur'
    }
    fluxLabel = {
        relance: 'Relance',
        echeancier: 'Echeancier',
        factES: 'Facture E/S',
        PerCyc: 'Percepteur cycle',
        PerES: 'Percepteur E/S',
        factCycl: 'Facture cycle',
    }
    toppings = new UntypedFormControl(['relance']);
    instance = new UntypedFormControl('03');
    gn_instances = [];
    fluxList = [{key: 'factCycl', label: 'Facture cycle'}, {key: 'factES', label: 'Facture E/S'}, {key: 'PerCyc', label: 'Percepteur cycle'}, {key: 'PerES', label: 'PerES'}, {key: 'relance', label: 'Relance'}, {key: 'echeancier', label: 'Echeancier'}];
    status = [{key: 'en_cours', label: 'En cours'}, {key: 'terminer', label: 'Terminé'},{key: 'erreur', label: 'Erreur'}];
    displayedColumns: string[] = ['position', 'name', 'traite', 'dv', 'dc', 'de', 'dp', 'nbd', 'nbc', 'nba', 'c', 'coment', 'action'];
    offset = '';
    error = false;
    loadMore = false;
    version1 = true;
    filtersLots = {};
    filesFilter = [];
    errortraite = 0;
    cuurentLot = 0;
    GN_REGIONS = {
        "13": "H0",
        "03": "B0",
        "14": "I0",
        "06": "D0",
        "11": "G0",
        "07": "E0",
        "08": "F0"
    }
    lotsDay = 0;

    constructor(private monitoringService: MonitoringService, public dialog: MatDialog,  private route: ActivatedRoute, private datePipe: DatePipe, public userLogin: UserLoginService) {
    }

    setFlux(flux) {
        this.flux = flux;
        switch (flux) {
            case 'cycle':
                this.params.flux = 'facture cycle,percepteur cycle';
                this.version1 = true; 
                break;
            case 'es':
                this.params.flux = 'facture E/S,percepteur E/S';
                this.version1 = false; 
                break;
        }
    }

    agregate(lots) {
        lots.forEach(lot => {
            lot.precompose = 0;
            lot.compose = 0;
            lot.archive = 0;
            lot.envoye = 0;
            if (lot.fichier_traites)
            lot.fichier_traites.forEach(traite => {
                lot.precompose += traite.nombre_communication_precompose;
                lot.compose += traite.nombre_communication_compose;
                lot.archive += traite.nombre_communication_archive;
                lot.envoye += traite.nombre_communication_envoye;
            });
        });
        return lots
    }

    getLots(more = false) {
        this.loading = true;
        if (this.params.traite || this.params.ssj) {
            let params = {
                instance: this.instance.value,
                flux: this.toppings.value
            }
            
            if (this.params.traite)
                params['traite'] = this.params['traite'];
            if (this.params.ssj)
                params['ssj'] = this.params['ssj'];
            this.monitoringService.getFilesTraite(params).subscribe((data) => {
                let fids = {}
                if (data.items && data.items.length > 0) {
                    data.items.forEach((item)=> {
                        fids[item.fichier_id] = true;
                    })
                    let paramFile = {traite: Object.keys(fids).join('File'), region: this.GN_REGIONS[this.instance.value]}
                    this.monitoringService.getFile(paramFile).subscribe((data) => {
                        this.files = this.agregate(data.body.result);
                        this.lotOffset = data.body.offset;
                        this.filesFilter = this.files;
                    })
                } else {
                    this.files = []
                    this.filesFilter = this.files;
                }
                this.loading = false;
            }, err => {
                this.loading = false;
                this.error = true;
                console.log('err', err);
            });
        } else {
            let params : any = {
                region: this.GN_REGIONS[this.instance.value],
                'dateGte': this.datePipe.transform(this.params['date-emission[gte]'], 'yyyy-MM-dd'),
                'page[size]': 10
            }
            if (this.toppings.value)
                params.flux = this.toppings.value.map(elm => {
                    return this.fluxEnum[elm]
                }).join(',')
            if (this.params['date-emission[lte]'])
                params.dateLte = this.datePipe.transform(this.params['date-emission[lte]'], 'yyyy-MM-dd')
            if (more && this.lotOffset)
                params['page[offset]'] = this.lotOffset.replace('==', 'SIGN');
            

            this.monitoringService.getFile(params).subscribe((data) => {
                if (data.body?.result) {
                    if (more) {
                        this.files = this.files.concat(this.agregate(data.body.result));
                    } else {
                        this.files = this.agregate(data.body.result);
                    }
                    this.calculs(this.files);
                    this.lotOffset = data.body.offset;
                    this.filesFilter = this.files;
                } else {
                    this.files = [];
                    this.lotOffset = '';
                    this.filesFilter = [];
                }
                this.loading = false;
            }, err => {
                this.loading = false;
                this.error = true;
                console.log('err', err);
            });
        }
    }

    filter(flux) {
        this.traites = [];
        this.traitesV2 = [];
        this.files = [];
        this.filesFilter = [];
        this.lotOffset = '';
        this.offset = '';
        if (flux == 'lot' || flux == 'relance') {
            this.flux = flux;
            this.getLots(flux);
        }
        else {
            this.setFlux(flux);
            this.search();
            this.items = [];
        }
    }

    expand(file) {
        file.display = !file.display;
    }

    isDisabled() {
        return false;
    }

    filterLot() {
        this.getLots(true)
    }

    search(more = false) {
        this.error = false;
        if (this.flux == 'lot') {
            this.getLots()
            return;
        }
        if (this.instance.value) {
            this.params.instance = this.instance.value;
        }
        let params: any = {
            'page[size]': 10
        };
        if (this.flux == 'es')
            params['page[size]'] = 400
        else 
            params['page[size]'] = 10
        Object.keys(this.params).forEach(elm => {
            if (this.params[elm]) {
                if (elm.includes('date-emission')) {
                    params[elm] = this.datePipe.transform(this.params[elm], 'yyyy-MM-dd');
                } else {
                    params[elm] = this.params[elm];
                }
            }
        });
        // if (this.toppings.value) {
        //     params.status = [];
        //     this.toppings.value.forEach(val => {
        //         params.status.push(this.statusKey[val]);
        //     });
        //     params.status = params.status.join(',');
        // }
        if (more && this.offset) {
            params['page[offset]'] = this.offset.replace('==', 'SIGN');
        }
        if (!more) {
            this.items = [];
        }
        this.loading = true;
        this.error = false;
        this.monitoringService.getFilesTraite(params).subscribe((data) => {
            this.offset = data.offset;
            this.items = this.items.concat(data.items)
            this.traites = [];
            this.traitesV2 = [];
            let traiteUniq = {};
            let traiteGroup = {};
            console.log("this.items", this.items);
            this.calculsTraites(this.items);
            this.items.forEach((elm)=> {
                if (elm) {
                    const key = elm.traite + elm.code_facturation + elm.date_emission + elm.fichier_id;
                    let item = traiteUniq[key];
                    if (item) {
                        let d1 = new Date(elm.date_precomposition);
                        let d2 = new Date(item.date_precomposition);
                        if (d1.getTime() - d2.getTime() > 0) {
                            traiteUniq[key] = elm;
                        }
                    } else {
                        traiteUniq[key] = elm;
                    }
                }
            });
            Object.keys(traiteUniq).forEach((key)=> {
                const elm = traiteUniq[key];
                this.traites.push(elm);
                if (!this.version1) {
                    const keyGroup =  [elm.fichier_id, elm.date_emission, elm.code_facturation, elm.date_precomposition].join('/');
                    let item = traiteGroup[keyGroup];
                    if (item) {
                        item.traites.push(elm.id)
                        item.traitesDetail.push(elm)
                    } else {  
                        const {fichier_id, date_emission, code_facturation, date_precomposition, ssj, instance, flux, type_facturation} = elm;
                        traiteGroup[keyGroup] = {
                            fichier_id, date_emission, code_facturation, date_precomposition, ssj, instance, flux, type_facturation, traites:[elm.id], traitesDetail: [elm]
                        };
                    }
                }
            });
            Object.keys(traiteGroup).forEach((key) => {
                this.traitesV2.push(traiteGroup[key])
            });
            this.traitesV2 = this.traitesV2;
            this.traites = this.traites;
            localStorage.setItem('data', JSON.stringify({params,
                flux: this.flux,
                traitesV2: this.traitesV2,
                traites: this.traites,
                gn_instances: this.gn_instances
            }));
            this.search_params.ssj = this.params.ssj
            this.search_params.flux = this.flux
            this.search_params.flux_param = this.params.flux
            this.search_params.instance = this.params.instance
            this.search_params.traite = this.params.traite
            localStorage.setItem('search_params', JSON.stringify(this.search_params))
            this.loading = false;
            this.loadMore = !!data.offset;
        }, err => {
            this.loading = false;
            this.error = true;
            localStorage.setItem('data', JSON.stringify({params}));
            console.log('err', err);
        });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(FiltersAdvencedComponent, {
            width: '600px',
            backdropClass: "bdrop",
            data: {
                params: this.params,
                flux: this.flux
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.upodate) {
                this.params = {...this.params, ...result};
                this.search_params = {...this.search_params, ...result};
                this.search();
            }
        });
    }

    more() {
        this.search(true);
    }

    moreLots() {
        this.getLots(true);
    }

    calculs(data) {
        let params : any = {
            region: this.GN_REGIONS[this.instance.value],
            'dateGte': this.datePipe.transform(this.params['date-emission[gte]'], 'yyyy-MM-dd'),
            'page[size]': 200
        }
        this.lotsDay = 0;
        this.errortraite = 0;
        this.cuurentLot = 0;
        data.forEach(lot => {
            if ([lot.status_archive, lot.status_compose, lot.status_envoye].indexOf('en cours') !== -1)
                this.cuurentLot = this.cuurentLot + 1;
            let day =  this.datePipe.transform(this.dateObj, 'yyyy-MM-dd');
            if (lot.date_traitement == day) {
                this.lotsDay = this.lotsDay + 1;
            }
        });
    }

    calculsTraites(data) {
        this.errortraite = 0;
        data.forEach((traite, index) => {
            console.log('traite', traite);
            if (traite != null && (traite.status_archive == 'erreur' || traite.status_envoye == 'erreur' || traite.status_compose == 'erreur'))
                this.errortraite = this.errortraite + 1;
        });
    }

    ngOnInit(): void {
        /*
        let action = {
          "action": "valider",
          "matricule": "wendy.dacourt.ext",
          "date_action": "2022-08-03T06:27:41.000Z",
          "code_facturation": "22109",
          "type_facturation": "C",
          "fichier_id": "2022060901261729643.GN.P.B0.FA.V",
          "traite": "207",
          "date_emission": "2022-06-10,2022-06-09",
          "comment": ""
        }
        this.monitoringService.postAction(action).subscribe(data => {
            console.log("data", data)
        });
        */
        const source = this.route.snapshot.paramMap.get('source');
        if (source == 'sheet') {
            let oldData = JSON.parse(localStorage.getItem('data'));
            this.params.traite = oldData?.params.traite || '';
            this.params.ssj = oldData?.params.ssj || '';
            if (oldData.gn_instances) {
                this.gn_instances = oldData.gn_instances;
                this.instance = new UntypedFormControl(this.gn_instances[0]);
            }
            let statuslabels = oldData?.params.status.split(',').map((label) => {
                switch (label) {
                    case 'en cours':
                        return 'en_cours';
                    case 'terminer':
                        return 'terminer';
                    case 'erreur':
                        return 'erreur';
                }
            })
            this.toppings = new UntypedFormControl(statuslabels);
            this.params.status = oldData?.params.status || '';
            this.traites = oldData.traites || [];
            this.traitesV2 = oldData.traitesV2 || [];
            this.setFlux(oldData.flux);
        } else {
            // this.gn_instances = ['03', '11', '08']; 
            // this.instance = new UntypedFormControl(this.gn_instances[0]);
            this.monitoringService.getHabilitation().subscribe(resp => {
                const data = resp['SHADOK_SUIVI_PROD'];
                if (data && data['gn_instances']) {
                    this.gn_instances = data['gn_instances'].sort();  
                    this.instance = this.search_params && this.search_params.instance ? new UntypedFormControl(this.search_params.instance) : new UntypedFormControl(this.gn_instances[0]);
                }
                if(this.gn_instances.length == 0) {
                    this.userLogin.errorHabilitation();
                }
                this.filter(this.search_params.flux);
            });
        }     
    }
}
