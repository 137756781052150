<h4>Bonjour  {{profile?.user?.name}}</h4>
<div class="content">
    <mat-card>
        <h6> <mat-icon aria-hidden="false">visibility</mat-icon> Suivi </h6>
        <button mat-raised-button class="btn-secondary" [mat-dialog-close]="true" [routerLink]="['/monitoring/prod/home']" >
            <mat-icon aria-hidden="false" aria-label="Example home icon">trending_up</mat-icon>
            Suivi de production
        </button>
        <button mat-stroked-button class="btn-secondary" [mat-dialog-close]="true" [routerLink]="['/monitoring/report']" >
            <mat-icon aria-hidden="false" aria-label="Example home icon">description</mat-icon>
            Rapports de composition
        </button>
    </mat-card>
    <mat-card>
        <h6><mat-icon aria-hidden="false">contact_mail</mat-icon> Commmunication en lot </h6>
        <button mat-raised-button class="btn-secondary" [mat-dialog-close]="true" [routerLink]="['/communication/create']">
            <mat-icon aria-hidden="false" aria-label="Example home icon">note_add</mat-icon>
            Faire une demande
        </button>
        <button mat-stroked-button class="btn-secondary" [mat-dialog-close]="true" [routerLink]="['/communication/list']">
            <mat-icon aria-hidden="false" aria-label="Example home icon">format_list_bulleted</mat-icon>
            Gérer mes demandes
        </button>
    </mat-card>
</div>
