<div class="buttons">
    <div class="button-left">
        <div class="button" [ngClass]="{'actif': flux == 'lot'}" (click)="filter('lot')">
            Suivi par lot
        </div>
        <div class="button" [ngClass]="{'actif': flux == 'cycle'}" (click)="filter('cycle')">
            traités en cycle
        </div>
        <div class="button"  [ngClass]="{'actif': flux == 'es'}" (click)="filter('es')">
            traités e/s
         </div>
         <!--div class="button disabled">
            plans de mensu (à venir)
         </div-->
    </div>
</div>
<div class="indics-goup" [ngClass]="{'reverse': flux == 'lot'}">
    <div class="indics" *ngIf="flux == 'lot'">
        <div class="waiting">
            <img alt="logo" class="logo" src="../assets/img/waiting.png" />
        </div>
        <div class="indicateur br-left">
            <p class="number">{{cuurentLot}}</p>
            <p class="label">TOTAL DES LOTS EN COURS</p>
        </div>
        <div class="indicateur">
            <p class="number">{{lotsDay}}</p>
            <p class="label">Nouveaux ce jour</p>
        </div>
    </div>
    <!--div class="indics traite" *ngIf="flux == 'cycle' || flux == 'es'">
        <div class="warnning">
            <img alt="logo" class="logo" src="../assets/img/warnning.png" />
        </div>
        <div class="indicateur">
            <p class="number">{{errortraite}}</p>
            <p class="label">Traités en alerte</p>
        </div>
    </div-->
</div>

<div class="block-filters">
    <div class="filter">
        <div class="label">Instance(s)</div>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="instance">
                <mat-select-trigger>
                    <div class="elmts" >
                        <div style="margin: 5px;">
                            {{instance.value}}
                        </div>
                    </div>
                </mat-select-trigger>
                <mat-option *ngFor="let op of gn_instances" [value]="op">{{op}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="filter">
        <div class="label">Traité</div>
        <input matInput placeholder="" [(ngModel)]="params.traite">
    </div>
    <div class="filter">
        <div class="label">SSJ</div>
        <input matInput placeholder="" value=""  [(ngModel)]="params.ssj">
    </div>
    <div class="filter large" *ngIf="flux == 'lot'">
        <div class="label">Type de flux</div>
        <mat-form-field appearance="fill" [ngClass]="{'multiple': toppings?.value?.length > 1}">
            <mat-select multiple [formControl]="toppings">
                <mat-select-trigger>
                    <div class="elmts" >
                        <div *ngFor="let elm of toppings.value;  let i=index">
                            <div *ngIf="i<2" class="elmt">
                                <label>{{fluxLabel[elm]}}</label>
                                <mat-icon>highlight_off</mat-icon>
                            </div>
                        </div>
                        <div class="elmt more" *ngIf="toppings?.value?.length > 2">
                            <label>...</label>
                        </div>
                    </div>
                </mat-select-trigger>
                <mat-option *ngFor="let op of list" [value]="op.key">{{op.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="actions">
        <a (click)="openDialog()">Recherche avancée</a>
        <button mat-stroked-button class="btn-next run" (click)="search()" [disabled]="isDisabled()">
            Rechercher
        </button>
    </div>
</div>
<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
<app-traite-list
                *ngIf="flux == 'es' || flux == 'cycle'"
                [list]="traites" [list2]="traitesV2" [loading]="loading"  [flux]="flux" 
                [loadMore]="loadMore" [error]="error" (more)="more()"
                [version1]="version1">
</app-traite-list>
<app-group-by   *ngIf="flux == 'lot' || flux == 'relance'" [flux]="flux" 
                [list]="filesFilter" [loading]="loading"  [filters]="filtersLots"
                [loadMore]="!!lotOffset" [error]="error" (more)="moreLots()">
</app-group-by>

