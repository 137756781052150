import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {UserLoginService} from "./user-login.service";

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(public router: Router, private userLoginService: UserLoginService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      console.log('intercepter')
      const token = JSON.parse(localStorage.getItem('token'));
      console.log("request", request.headers);
      if (token) {
          request = request.clone({ headers: request.headers.set('x-shadok-webapp-key', token.access_token) });
      }

    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
            console.log('errorr', error);
            this.userLoginService.checkToken();
          if (error.error instanceof ErrorEvent) {
            // client-side error
            // console.error(`${error.error.message}`);
          } else {
            // if (/^5\d+/.test(error.status.toString())) {
            //   this.router.navigate(['/error', error.status.toString()]);
            // }
          }
          return throwError(error);
        })
      );
  }
}

