import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-group-by',
  templateUrl: './group-by.component.html',
  styleUrls: ['./group-by.component.scss']
})
export class GroupByComponent implements OnInit {

  @Input()
  listLots = []
  @Input()
  set list(val: any[]) {
    this.listLots = val;
    val.forEach((item) => {
      console.log('item compose <>>>>>>>>>>', item.compose);
      if (item.fichier_traites) {  
        item.precompose = 0;
        item.compose = 0;
        item.archive = 0;
        item.envoye = 0;
        item.fichier_traites.forEach((elm)=> {
          item.precompose += elm.nombre_communication_precompose || 0;
          item.compose += elm.nombre_communication_compose || 0;
          item.archive += elm.nombre_communication_archive || 0;
          item.envoye += elm.nombre_communication_envoye || 0;
        })
      }
      console.log('item compose <>>>>>>>>>>', item.compose);
    })
  }
  @Input()
  set filters(val: any) {
    console.log('val', val)
    console.log('list', this.list)
  }
  @Input()
  loading = false;
  @Input()
  error = false;
  @Input()
  loadMore = true;
  @Output()
  more = new EventEmitter<any>();
  @Input()
  params = {}
  @Input()
  version1 = false;
  p: number = 1;
  @Input()
  flux = '';

  GN_REGIONS = {
    "H0": "13",
    "B0": "03",
    "I0": "14",
    "D0": "06",
    "G0": "11",
    "E0": "07",
    "F0": "08"
}


load() {
  this.more.emit();
}

ngOnInit(): void {
}

}
