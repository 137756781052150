import { Component, ViewChild, ElementRef  } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../services/user-login.service'

declare var window: any

@Component({
  selector: 'google-signin-btn',
  templateUrl: './google-signin-btn.component.html',
})
export class GoogleSigninBtnComponent {

  @ViewChild("signinGoogleBtn", {static: false}) signinGoogleBtn!: ElementRef;
  public userLoggedIn: boolean = false

  public googleInit() {
    setTimeout(()=>{
      window.google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: (token: any) => {
          this.userLogin.onLoginSuccess(token)
        },
      })
      window.google.accounts.id.renderButton(
        this.signinGoogleBtn.nativeElement,
        { theme: "outline", size: "large" } 
      );
    }, 1000)
  }

  constructor(private userLogin: UserLoginService, private router:Router){
    
  }

  ngAfterViewInit() {
    this.userLogin.isAuthenticated(this)
  }

  isLoggedIn(_: any, loggedIn: boolean) {
    if (!loggedIn) {
      this.googleInit()
    }else{
      this.userLoggedIn = true
      this.router.navigate(['/monitoring/prod/home'])
    }
  }
  signOut(): void {
    window.google.accounts.id.revoke(environment.googleClientId)
    this.userLogin.onLogoutSuccess()
  }
}
