import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { UserLoginService } from "../services/user-login.service";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    public userLogin: UserLoginService,
    public router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userLogin.isLoggedIn().pipe(
      tap((loggedIn) => {
        console.log("AuthGuard : loggedIn :", loggedIn);
        if (!loggedIn) {
          this.router.navigate(["/"]);
        }
      }),
    );
  }
}
