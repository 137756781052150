import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})

export class MonitoringComponent implements OnInit {
    columns = ['Instance'];
    displayedColumns: string[] = ['Instance', 'Traité', 'Nom', 'Lot GN', 'Statut', 'Date de compo', "Date d'emission", 'NB com att', 'Nb com composé', 'Nb plis archivé', 'Nb mail', 'Nb GC', 'Nb déclassé', 'Action', 'Commentaire'];
    dataSource: MatTableDataSource<any>;

    routes = [{
        url: '/monitoring',
        label: 'Suivi de production',
        icon: 'trending_up'
    },{
        url: '/monitoring/report',
        label: 'Rapport de composition',
        icon: 'description'
    }];

    constructor() { }


   ngOnInit(): void {
   }

}
