import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-number-details',
  templateUrl: './number-details.component.html',
  styleUrls: ['./number-details.component.scss']
})
export class NumberDetailsComponent implements OnInit {

  @Input() data: any;

  info = {
      C: 'Courrier',
      D: 'Démat Particulier',
      Nm: 'Notification démat',
      DGCedi: 'Démat Grands Comptes EDI',
      DGCcho: 'Démat Grands Comptes Chorus'
  };
  constructor() {
  }

    returnZero() {
        return 0
    }

    ngOnInit(): void {

  }

}
