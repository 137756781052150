import { Component, OnInit } from '@angular/core';
import { TimelineItem } from 'ngx-vertical-timeline';
import {MonitoringService} from "../../services/monitoring.service";
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { UntypedFormControl } from '@angular/forms';
import {UserLoginService} from "../../../services/user-login.service";

export interface Rapport {
    file: string;
    region: string;
    subRegion: string,
    instance: string;
    ssj: number;
    traite: number;
    typeDoc: string;
    total: number;
    totalDecl: number;
    multiexampl: number;
    multifolio: number;
    dateCompo: string;
    dateEmiss: string;
    dateRegl: string;
    datePrel: string;
    comment: string
}

@Component({
    selector: 'app-report-multiple',
    templateUrl: './report-multiple.component.html',
    styleUrls: ['./report-multiple.component.scss']
})

export class ReportMultipleComponent {

    fichier_id = 'a3495e99db62483aabc08e0f114409fe';
    items = Array.from({length: 100000}).map((_, i) => `Item #${i}`);
    loading = false;
    elmts = [];
    elements_composes = {};
    traites = [];
    file : any = {};
    traite = new UntypedFormControl();
    total = '-';
    traitesDisplay: any[] = [];
     types = {
        ars: "Ars",
        courrier: "Courrier",
        encart: "Encart",
        rs: "Reglement service",
        glossaire: "Glossaire",
        messages: "Messages",
    };
    stats:any = {};
    suivie = [{
        title: "Pré-composées"
    }]
    traitesIdNum = [];
    allTraites = [];
    steps = [ {key:'compose', label: 'Composées'},
            {key:'archive', label: 'Archivées'}, {key:'envoye', label: 'Envoyées', date: 'date_emission'}];
    selectedTraite = '';
    actions = [];
    fichierId = "";
    comment: "";
    sumbitting = false;
    comments = [];
    traiteFilter = [];
    key = '';

    onKeyUp(event) {
        event.stopPropagation()
        console.log('event', this.key)
        this.traite = new UntypedFormControl({id: '', num: 'Tous les traités'})
        if (this.key) {
            this.traiteFilter = this.traitesIdNum.filter(traite => traite.num.includes(this.key))
        } else {
            this.traiteFilter = this.traitesIdNum;
            this.traite = new UntypedFormControl({id: '', num: 'Tous les traités'})
            this.changeTraite({id: '', num: 'Tous les traités'})
        }

    }

    calcul(data) {
        const localsteps = this.steps.concat([{key:'precompose', label: 'Pré-composées', date: 'date_precomposition'}]);
        this.stats = {
            precompose_total: 0,
            precompose_c:0,
            precompose_d:0,
            precompose_dec:0,
            precompose_dgc:0,
            compose_total: 0,
            compose_c:0,
            compose_d:0,
            compose_dec:0,
            compose_dgc:0,
            envoye_total: 0,
            envoye_c:0,
            envoye_d:0,
            envoye_dec:0,
            envoye_dgc:0,
            archive_total: 0,
            archive_c:0,
            archive_d:0,
            archive_dec:0,
            archive_dgc:0,
        };

        data.forEach(element => {
            localsteps.forEach((item, index) => {
                const elm = item.key
                const total = element['nombre_communication_' + elm]
                console.log('element', element['nombre_communication_' + elm])
                this.stats[elm + '_d'] += element['nombre_demat_' + elm] || 0;
                this.stats[elm + '_c'] += element['nombre_courrier_' + elm] || 0;
                this.stats[elm + '_dec'] += element['nombre_declasses_' + elm] || 0;
                this.stats[elm + '_dgc'] += element['nombre_dgc_' + elm] || 0;
            });
        });
        localsteps.forEach((item, index) => {
            const elm = item.key
            this.stats[elm + '_total'] = this.stats[elm + '_d'] + this.stats[elm + '_c'] + this.stats[elm + '_dec'] + this.stats[elm + '_dgc']
        });
        /*
        steps.forEach((item, index) => {
            console.log('item, index', item, index);
            const elm = item.key
            const total = this.file['nombre_communication_' + elm]
            this.stats.push({
                label : item.label,
                date: this.file[item.date],
                totalval: total,
                d: ((this.file['nombre_demat_' + elm] || ' 0') + '/' + total),
                c: ((this.file['nombre_courrier_' + elm] || ' 0') + '/' + total),
                dec: ((this.file['nombre_declasses_' + elm]|| ' 0') + '/' + total),
                dgc: ((this.file['nombre_dgc_' + elm]|| ' 0')+ '/' + total),
                total: (total + '/' + total)
            })
            if (elm == 'compose' && total)
                return;
        })
        */
    }

    getTypeLabel(key) {
        if (this.types[key]) {
            return this.types[key]
        }
        return key;
    }

    constructor(private monitoringService: MonitoringService, private route: ActivatedRoute, public userLoginService: UserLoginService,) {}

    
    prepareData(val) {
        let elements_composes = [];
        let elements_key = {};
        this.traitesDisplay = [];
        this.allTraites.forEach(file => {
            if(val && file.id != val)
                return;
            this.traitesDisplay.push(file);
            if (file.elements_composes) {
                file.elements_composes.forEach(element => {
                    if (elements_key[element.libelle]) {
                        elements_key[element.libelle].nombre_document += element.nombre_document;
                    } else {
                        elements_key[element.libelle] = {...element};
                    }
                });
            }
            if (val)
                this.prepareAction(file)
        });
        Object.keys(elements_key).forEach((key)=> {
            elements_composes.push(elements_key[key]);
        });
        this.file.elmts = _.groupBy(elements_composes, (elmt) =>{
            return elmt.type_element;
        });
        this.calcul(this.traitesDisplay);
    }
    prepareAction(val) {
        this.actions = [];
        val.actions.forEach(item => {
            console.log('item', item.matricule);
            if (!item.matricule) 
                return;
            if(item.matricule == 'Hermes.prepare')
                item.name = 'Hermes';
            else {
                let nameSplit = item.matricule.split('.');
                if (nameSplit && nameSplit.length >= 2)
                    item.name = nameSplit[0]+' '+nameSplit[1]
            }
            const actions = {
                creer: "crée",
                attribuer: "attribué",
                recomposer: "recomposé",
                valider: "validé",
                terminer: "terminé",
                annuler: "annulé",
                supprimer: "supprimé",
                preparer: "Initialisation"
            }
            
            let act = actions[item.action]
            if (act) {
                item.act = act;
                this.actions.push(item)
            }
        });
        if (val.comments) {
            val.comments.forEach(item => {
                console.log('item', item.matricule);
                if (!item.matricule) 
                    return;
                else {
                    let nameSplit = item.matricule.split('.');
                    if (nameSplit && nameSplit.length >= 2)
                        item.name = nameSplit[0]+' '+nameSplit[1]
                }
                item.comment;
                item.date_action = item.created_at;
                this.actions.push(item);
            });
        }

        this.actions = this.actions.sort((id1, id2) => {
            return new Date(id2.date_action).getTime() - new Date(id1.date_action).getTime();
        });
        console.log('actions', this.actions);
    }
    changeTraite(val) {
         this.prepareData(val.id);
         this.fichierId = val.id;
         console.log('change this.fichierId', this.fichierId);
    }

    async hackSequeceRequest(traites) {
        const requests = traites.length / 100
        let resp = [];
        let source = traites;
        console.log('traites', traites.length)
        console.log('requests', requests)
        if (requests > 2) {
            traites.legth = 100
            this.monitoringService.getFileTraites(traites.slice(200)).subscribe(res1 =>  {
                resp = res1.body;
                this.monitoringService.getFileTraites(traites.slice(100, 200)).subscribe(res2 =>  {
                    resp = resp.concat(res2.body);
                    traites.length = 100
                    this.monitoringService.getFileTraites(traites).subscribe(res3 =>  {
                        resp = resp.concat(res3.body);
                        this.displayReport(resp)
                    })
                })
            })
            return;
        }

        traites.legth = 100
        this.monitoringService.getFileTraites(traites.slice(100)).subscribe(res1 =>  {
            resp = res1.body;
            traites.length = 100
            this.monitoringService.getFileTraites(traites).subscribe(res2 =>  {
                resp = resp.concat(res2.body);
                this.displayReport(resp)
            })
        })
    }

    resolveAfter2Seconds(traites) {
        return new Promise(resolve => {
            this.monitoringService.getFileTraites(traites).subscribe(resp => {
                resolve(resp.body);
            });


        });
      }

    displayReport(data) {
        this.allTraites = data;
        setTimeout(() => {
            if (data && data.length > 0) {
                this.file = {...data[0]};
                if (data.length >= 2) {
                    this.file.traite = '99'+ this.file.type_facturation
                } else {
                    this.fichierId = this.file.id;
                    this.prepareAction(this.file)
                }

                data.forEach(file => {
                    this.traitesIdNum.push({
                        id: file.id,
                        num: file.traite
                    })
                })
            }
            this.traitesIdNum = this.traitesIdNum.sort((id1, id2) => {
                return id1.num - id2.num;
            });
            this.traiteFilter = this.traitesIdNum;
            this.prepareData('');
            this.loading = false;
        }, 100)
    }

    ngOnInit(): void {
        const ids = this.route.snapshot.queryParamMap.get('ids');
        if (ids === null) {
            this.traites = new Array<string>();
          } else {
            this.traites = JSON.parse(ids);
          }
//        this.traites = ['29c0d67a8ac54778b08be917282b604e', '30a6a24f7a1f4063908b6e6653cd8c31', 'b296f307a1cc4472a0e29d5c65f965d3'];
        this.traitesIdNum = [{id: '', num: 'Tous les traités'}];
        this.fichier_id = this.route.snapshot.paramMap.get('id');
        this.loading = true;
        if (this.traites.length > 100) {
            this.hackSequeceRequest(this.traites);
            return;
        }
        this.monitoringService.getFileTraites(this.traites).subscribe(resp => {
            this.displayReport(resp.body);
        });

    }

    submit() {
        if (this.sumbitting) {
            return;
        }
        this.sumbitting = true;
        let user = this.userLoginService.getProfile();
        console.log('user', user);
        let data = {
            matricule: user?.user?.email,fichier_traite_id: this.fichierId, comment: this.comment, date_comment: new Date()
        }
        console.log('comment', data)
        this.monitoringService.addComment(data).subscribe(resp => {
            this.sumbitting = false;
            this.comment = '';
            data['created_at'] = new Date();
            this.file.comments.push(data)
            this.prepareAction(this.file)
        }, err => {
            this.sumbitting = false;
        });
    }

}