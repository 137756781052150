import { Component, OnInit, Optional, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-filters-advenced',
  templateUrl: './filters-advenced.component.html',
  styleUrls: ['./filters-advenced.component.scss']
})
export class FiltersAdvencedComponent implements OnInit {

  dateGte = new UntypedFormControl();
  dateLte = new UntypedFormControl();
  code = new UntypedFormControl();
  flux = 'es'
  params: any;
  constructor(private dialogRef: MatDialogRef<FiltersAdvencedComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.params = data.params;
    this.flux = data.flux
    console.log('this.dateGte', this.params['date-emission[gte]']);
    this.dateGte = new UntypedFormControl(this.params['date-emission[gte]']);
    this.dateLte = new UntypedFormControl(this.params['date-emission[lte]']);
    this.code = new UntypedFormControl(this.params['code_facturation']);
  }

  getToppings() {
    return this.code.value;
  }

    valid() {
        let params = {
            'date-emission[gte]': this.dateGte.value,
            'date-emission[lte]': this.dateLte.value,
            'code_facturation' : this.code.value,
            upodate: true
        }
        console.log('params', params);
        this.dialogRef.close(params);

    }
    cancel() {

    }

  ngOnInit(): void {

  }

}
