import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, Injector, NgZone, EventEmitter } from '@angular/core';

import { User } from '../models/user';
import { RoleEnum } from '../enums/role.enum';
import { URLS } from '../../environments/urls';
import { UtilsService } from '../services/utils.service';
import { AwsService } from './aws.service';
import { AuthProviderService } from '../interfaces/auth-provider.interface';
import { environment } from '../../environments/environment';

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

@Injectable({
    providedIn: 'root'
})
export class UserLoginService {
    private authProvider: AuthProviderService;

    private profileSource: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

    public profile$: Observable<User> = this.profileSource.asObservable();

    public loginStatusChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    error = new EventEmitter<string>();

    batch = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private injector: Injector,
        private utilsService: UtilsService,
        private awsService: AwsService
    ) { 
        this.authProvider = injector.get(environment.auth.provider);
    }

    async init() {
        this.awsService.initAmplify();
        const authenticated = await this.authProvider.init(environment.auth.config);
        if (authenticated) {
            var redirectReversement = this.router.url === '/';
            this.onLoginSuccess(redirectReversement);
            setInterval(() => {this.refreshToken()}, 30 * 10000);  // 3 minutes
        }
    }

    public refreshToken(): void {
        this.authProvider.refreshToken().then((refreshed) => {
          if (refreshed) {
            this.onLoginSuccess(false);
          }
        }).catch((error => {
          console.log('error refreshing', error);
        }))
    }

    public setProfile(response: any): void {
        this.profileSource.next(response);
    }

    public getProfile() {
        return JSON.parse(localStorage.getItem('token'));
    }

    login() {
        this.authProvider.login(environment.auth.loginRedirectUri);
    }

    public setLoginStatus(status: boolean): void {
        this.loginStatusChanged.next(status);
    }

    private onAmplifyLoginSuccess = (user: any, redirect:boolean) => {
        if(redirect){
            const routerService = this.injector.get(Router);
            const ngZone = this.injector.get(NgZone);
            this.getHabilitations(user.email)
                .then((response) => {
                    if (response !== null && response['SHADOK_SUIVI_PROD'] !== null && response['SHADOK_SUIVI_PROD']['gn_instances'].length !== 0) {
                        this.setLoginStatus(false);
                        ngZone.run(() => {
                            routerService.navigate(['/monitoring/prod/home'], { skipLocationChange: true });
                        });
                    } else {
                        this.setLoginStatus(false);
                        this.error.emit('error authent');
                        this.signOut();
                    }
                })
                .catch((err) => {
                    this.setLoginStatus(false);
                    this.error.emit('error authent');
                    this.signOut();
                });
        }
    };

    public onLoginSuccess = async (redirect: boolean = true) => {
        const profile = await this.authProvider.getUserInfo();
        const userData = {
            ...profile,
            isLoggedIn: true,
            username: `${profile.firstName} ${profile.lastName}`,
        };
        const token = this.authProvider.getUserToken();
        if (!token) return;
        const expires_at = this.authProvider.getUserTokenExpiration() * 1000
        const user = {
            email: userData.email,
            name: userData.username,
        };

        localStorage.setItem('token', JSON.stringify({ access_token: token, expires_at, user }));

        Auth.currentAuthenticatedUser()
        .then((currentUser) => {
            if (!currentUser) {
                this.signInToAmplify(userData, user, redirect);
            } else {
                this.onAmplifyLoginSuccess(user, redirect);
            }
        })
        .catch((error) => {
            this.signInToAmplify(userData, user, redirect);
            console.error('Error fetching current authenticated user:', error);
        });
    };

    private signInToAmplify = (userData: any, user: any, redirect:boolean) => {
        try {
            Auth.federatedSignIn(
                environment.auth.federatedSignInProvider,
                {
                    token: this.authProvider.getUserToken(),
                    expires_at: this.authProvider.getUserTokenExpiration(),
                },
                userData
            ).then(() => this.onAmplifyLoginSuccess(user, redirect), (error) => console.error('Failed to sign in to Amplify:', error));
        } catch (error) {
            console.error('Failed to sign in to Amplify:', error);
            this.setLoginStatus(false);
        }
    };

    public onError = (error) => {
        console.log('onError', error)

        this.error = error
    }

    public onLogoutSuccess = () => {
        Auth.signOut({ global: true })
            .then(() => {
                this.router.navigate(['/landing']);
            })
            .catch(err => this.error = err);
    };

    public checkToken() {
        const token = JSON.parse(localStorage.getItem('token'));

        if (token && (token.expires_at - new Date().getTime()) <= 0) {
            this.signOut();
        }
    }

    public isAuthenticated(callback: LoggedInCallback): void {

        if (callback == null) {
            throw ('UserLoginService: Callback in isAuthenticated() cannot be null');
        }

        const token = JSON.parse(localStorage.getItem('token'));

        if (token && (token.expires_at - new Date().getTime()) <= 0) {
            this.utilsService.errorNotification('Utilisateur non connecté', 'Vous devez vous reconnecter. Vous avez perdu la connexion');
            this.signOut();
        }

        if (token && this.batch == true) {
            callback.isLoggedIn('Current user found', true);
        } else {
            Auth.currentAuthenticatedUser().then(user => {
                console.log('currentAuthenticatedUser user',user);
                if (user != null) {
                    this.getHabilitations(user.email)
                        .then(response => this.setProfile(response))
                        .catch(err => this.error = err);
                    callback.isLoggedIn('Current user found', true);
                } else {
                    callback.isLoggedIn('User not found', false);
                }
            }).catch(err => {
                callback.isLoggedIn(err, false);
            });
        }
    }

    public getHabilitations(email: string): Promise<User> {
        return this.http.get<User>(environment.apiURL + URLS.authent).toPromise();
    }

    public hasRight(requiredRoles: RoleEnum[]): boolean {
        const result: boolean[] = [];
        requiredRoles.forEach(value => {
            result.push(this.profileSource.getValue().roles.includes(value));
        });
        return result.includes(true);
    }

    private clearProfile () {
        this.profileSource.next(undefined);
        localStorage.removeItem('token');
        this.router.navigate(['/']);
    }

    public signOut(): void {
        if (this.batch) {
            this.clearProfile();
        } else {
            Auth.signOut({ global: true })
                .then(() => {
                    this.clearProfile();
                })
                .catch(error => this.error = error);
        }
    }

    public errorHabilitation(): void {
        this.utilsService.errorNotification('Erreur Habilitation', "Vous n'avez pas les droits pour accéder à cette application");
        this.signOut();
    }

    public logoutKeycloak(){
        this.authProvider.logout(environment.auth.logoutRedirectUri);
    }

    public isLoggedIn(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            map((result) => {
                return true;
            }),
            catchError((error) => {
                return of(false);
            })
        );
    }
}
