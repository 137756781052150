import Amplify from 'aws-amplify';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AwsService {

    constructor(
        private http: HttpClient
    ) { }

    public initAmplify(): void {
        Amplify.configure({
            Auth: {
                identityPoolId: environment.identityPoolId,
                region: environment.awsRegion,
                identityPoolRegion: environment.awsRegion,
                mandatorySignIn: true,
            }
        });
    }



      getpresignedurls() {
        return this.http.get<any>(environment.apiURL + '/' + 'file/presigned?type=json&source=CLOT');
      }

      public uploadfileAWSS3(data, file) { 
          console.log('uploadawsS3 body', { ...data.fields, file });
          const fd = new FormData();
          Object.keys(data.fields).forEach(key => {
            fd.append(key, data.fields[key]);
          })
          fd.append("file", file);
          console.log('fd', { ...fd });

          return  this.http.post(data.url, fd)  
      }

      synthese(idlot) {
        return this.http.get<any>(environment.apiURL + '/informations', {params :{ 'id-lot-prefix':  idlot}});
      }


      compose(data) {
        const headers= new HttpHeaders()
        .set('Content-Type', 'application/json; charset=utf-8')
        .set('Type-Communication', 'lot')
        .set('Cache-Control', 'no-cache')
        .set('aggregate', 'true');
        console.log("data", ...data)
        const body = {
            ...data,
            "fill-marque": true,
            "fill-contact": true,
            "fill-abonnement":false,
            "mode-interface": true,
            "systeme-emetteur": "CLOT",
            "datamaster": "DataMaster_Lot_Generique.wfd",
            }
        return this.http.post<any>(environment.apiURL+'/aggregate', body, {headers});

      }

    }