import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
@Component({
  selector: 'app-traite-list',
  templateUrl: './traite-list.component.html',
  styleUrls: ['./traite-list.component.scss']
})
export class TraiteListComponent implements OnInit {

  @Input()
  list : any[];
  listES: any[];
  @Input()
  set list2(val: any[]) {
    this.listES = val;
    val.forEach((item) => {
      if (item.traitesDetail) {
        item.nombre_communication_precompose = 0;
        item.nombre_communication_compose = 0;
        item.nombre_communication_archive = 0;
        item.nombre_communication_envoye = 0;
        item.traitesDetail.forEach((elm)=> {
          item.nombre_communication_precompose += elm.nombre_communication_precompose;
          item.nombre_communication_compose += elm.nombre_communication_compose;
          item.nombre_communication_archive += elm.nombre_communication_archive;
          item.nombre_communication_envoye += elm.nombre_communication_envoye;
        })
      }
    })
  }
  @Input()
  loading = false;
  @Input()
  error = false;
  @Input()
  loadMore = true;
  @Output()
  more = new EventEmitter<any>();
  @Input()
  version1 = false;
  p: number = 1;
  @Input()
  flux = '';

  pageChanged(event) {
    console.log('pageChanged', event)
  }
  constructor(private router: Router) { }

  load() {
    this.more.emit();
  }

  isDisabled(elm) {
    return (elm.traites && elm.traites.length > 15);
  }

  getClass(elm, current, next) {
    let classCss = '';
    if (elm['nombre_communication_' + current] == 0)
      classCss = 'hide';
    else if (elm['nombre_communication_' + next] == 0)
      classCss += ' last';
    return classCss;
  }



  getReport(elm) {
      const queryParams: any = {};
  
      let traites = (elm.traites && elm.traites.length > 0) ? elm.traites : [elm.id];
      queryParams.ids = JSON.stringify(traites);
  
      const navigationExtras: NavigationExtras = {
        queryParams
      };
      this.router.navigate(['/monitoring/reportes/'], navigationExtras);
    }

  ngOnInit(): void {
  }

}
