<div class="bg-primary">
    <header>
        <mat-toolbar color="accent" class="body">
            <a class="container-logo" routerLink="/monitoring/prod/home">
                <img alt="logo" class="logo" src="../assets/img/logo.png" />
            </a>
            <div style="flex: 1 1 auto;" *ngIf="!isLoginIn && router.url === '/'">
                <ul class="mt-3 list-inline intro-social-buttons float-right" id="sign-in">
                    <li>
                        <button class="login-button" (click)="login()" mat-raised-button>Se connecter</button>
                    </li>
                </ul>
            </div>
            <ng-container *ngIf="router.url !== '/'">
                <div class="nav-container">
                    <ul ngbNav #nav="ngbNav"  class="nav-tabs">
                        <li [ngbNavItem]="i" *ngFor="let link of routes[module]; let i = index">
                            <a ngbNavLink class="color-white" [routerLink]="[link.url]" class="link">
                                <mat-icon aria-hidden="false">{{link.icon}}</mat-icon>
                                <p>{{link.label}}</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon aria-hidden="false" class="icon-menu">person</mat-icon>
                    {{getProfileName()}} 
                </button>
                <label class="version">{{version}}</label>
                <mat-menu #menu="matMenu" class="menu-content" style=" position: relative;">
                    <button mat-menu-item  [routerLink]="['/monitoring/report']">Aide & Support</button>
                    <button mat-menu-item (click)="logout()" style="height: 55px;">Se déconnecter</button>
                </mat-menu>
            </ng-container>
        </mat-toolbar>
    </header>
</div>
