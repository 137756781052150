import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";

//import { environment } from '../../../environments/environment';
import {UserLoginService} from "../../services/user-login.service";
import { AwsService } from '../../services/aws.service';

//declare const gapi: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLoginIn = false;
  public auth2: any;
  @Input()
  module = '';
  public version: string = 'v2.0.4';
  routes = {
      monitoring:[{
          url: '/monitoring/prod',
          label: 'Suivi de production',
          icon: 'trending_up'
      },{
          url: '/monitoring/report',
          label: 'Rapport de composition',
          icon: 'description' 
      }],
      communication:[{
          url: '/communication/create',
          label: 'Faire une demande \n de composition',
          icon: 'note_add'
      },{
          url: '/communication',
          label: 'Gérer mes demandes',
          icon: 'format_list_bulleted'
      }]
  };


  constructor(public router: Router, public dialog: MatDialog,
              public userLoginService: UserLoginService,
              public awsService: AwsService) { }

    public googleInit() {
        /* gapi.load('auth2', () => {
            this.auth2.attachClickHandler(document.getElementById('sign-in'), {},
                this.userLoginService.onLoginSuccess,
                this.userLoginService.onError)
        }); */
    }

    getProfileName() {
        let data = this.userLoginService.getProfile();
        return data?.user?.name;
    }
    

    ngOnInit() {
        /* this.awsService.initAmplify();
        this.userLoginService.profile$.subscribe((profile) => {
           });
        gapi.load('auth2', () => {
            this.auth2 = gapi.auth2.init({
                client_id: environment.googleClientId,
                cookiepolicy: 'single_host_origin',
                approval_prompt: 'force',
                scope: 'email',
                prompt: 'select_account',
                plugin_name:'App Name that you used in google developer console API'
            })
        }); */
    }

    public logout(): void {
        this.userLoginService.signOut();
        this.userLoginService.logoutKeycloak();
        localStorage.setItem('search_params', JSON.stringify({flux: 'es'}))
    }

    public login() {
        this.userLoginService.login();
    }

}
