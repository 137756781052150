<a class="flex txt-grey txt-m" [routerLink]="['/monitoring/prod/home']">
    <mat-icon>chevron_left</mat-icon>
    <label>Retour à la Production en lot</label>
</a>
<div class="content">
    <div class="tag">Détails</div>
    <div class="title">
        <div class="status">
            <div>STATUT</div>
            <div class="val">{{ file.status }}</div>
            <div>Le {{ file.updated_at | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="support">
            <div class="data">
                <div class="item">
                    <div class="titl">Instance</div>
                    <div class="val">{{ file.instance }}</div>
                </div>
                <div class="item">
                    <div class="titl">SSJ</div>
                    <div class="val maxlength">{{ file.ssj }}</div>
                </div>
                <div class="item">
                    <div class="titl">traité</div>
                    <div class="val">{{ file.traite }}</div>
                </div>
                <div class="item">
                    <div class="titl">type de flux</div>
                    <div class="val">{{ file.flux }}</div>
                </div>
            </div>
            <div>Nom Du LOT : <b>{{ file.fichier_id || 'XXXXXXXXXXXXXXXXXXXXXX' }}</b></div>
        </div>
        <div class="dates">
            <div class="item">
                <div class="titl">Date d'émission</div>
                <div class="val">{{ file.date_emission | date: 'dd/MM/yyyy' }}</div>
            </div>
            <div class="item">
                <div class="titl">Date de reglement</div>
                <div class="val">{{ file.date_reglement | date: 'dd/MM/yyyy' }}</div>
            </div>
            <div class="item">
                <div class="titl">Date de prélevement</div>
                <div class="val">{{ file.date_prelevement | date: 'dd/MM/yyyy' }}</div>
            </div>
        </div>
        <div class="numbers">
            <div class="total">
                <div class="val">
                    {{stats.precompose_total}}
                </div>
                <div class="label">
                    <div>COMMUNICATIONS</div>
                    <div>ATTENDUES</div>
                </div>
            </div>
            <div class="multi">
                <div class="attr">
                    <div>MULTIEXEMP</div>
                    <div>MULTIFOLIO</div>
                </div>
                <div class="point">
                    <div>:</div>
                    <div>:</div>
                </div>
                <div class="val">
                    <div> {{file.multi_exemplaires}}</div>
                    <div>{{file.multi_folios}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="select" *ngIf="traitesIdNum.length > 2">
          <div class="filter">
            <div class="label">Filtrer par traité</div>
            <mat-form-field appearance="fill" style=" width: 150px !important;height: 50px;">
                <mat-select [formControl]="traite" (selectionChange)="changeTraite($event.value)">
                    <mat-select-trigger>
                        <div class="elmts">
                            <div style="margin: 5px;">
                                {{traite.value?.num}}
                            </div>
                        </div>
                    </mat-select-trigger>
                    <input matInput placeholder="Filtrer par traité" [(ngModel)]="key" (keyup)="onKeyUp($event)">
                    <mat-option *ngFor="let traite of traiteFilter" [value]="traite">
                        {{traite.num}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div>
        <h4>PARAMETRAGE D'ENVOI</h4>
        <div class="theader">
            <div class="lrg">Titre</div>
            <div class="lrg">référence</div>
            <div class="sm">volumétrie</div>
        </div>
        <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
            <div *cdkVirtualFor="let elm of file.elmts | keyvalue; ; let i = index" class="tcontent" [ngClass]="{'pair': i%2 == 0}">
                <label>{{getTypeLabel(elm.key)}}</label>
                <div class="row" *ngFor="let item of elm.value">
                    <div class="lrg">{{item.libelle}}</div>
                    <div class="lrg">{{item.cle_document}}</div>
                    <div class="sm">{{item.nombre_document}}</div>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
        <div></div>
    </div>
    <div>
        <h4>SUIVI DE PRODUCTION</h4>
        <div class="cards">
            <div class="card">
                <div class="header">
                    <mat-icon [ngClass]="{'actif': stats.precompose_total}">
                        {{ stats.precompose_total ? 'check_circle' : 'restore'}}
                    </mat-icon>
                    <div>Pré-composées</div>
                    <hr/>
                </div>
                <div class="body" *ngIf="stats.precompose_total">
                    <div class="attr">
                        <div>Courriers</div>
                        <div>Déclassés</div>
                        <div>Dématérialisés</div>
                        <div>DGC</div>
                        <br/>
                        <div>Total</div>
                    </div>
                    <div class="val">
                        <div>{{stats.precompose_c}}</div>
                        <div>{{stats.precompose_dec}}</div>
                        <div>{{stats.precompose_d}}</div>
                        <div>{{stats.precompose_dgc}}</div>
                        <br/>
                        <div class="date">
                            <b>{{stats.precompose_total}}</b>
                        </div>
                    </div>
                    <div class="val-date">
                        <div>.</div>
                        <div>.</div>
                        <div>.</div>
                        <div>.</div>
                        <br/>
                    </div>
                </div>
            </div>
            <div class="card" *ngFor="let item of steps">
                <div class="header">
                    <mat-icon [ngClass]="{'actif': stats.precompose_total}">
                        {{ stats[item.key + '_total'] == stats.precompose_total ? 'check_circle' : 'restore'}}
                    </mat-icon>
                    <div>{{item.label}}</div>
                    <hr/>
                </div>
                <div class="body" *ngIf="stats[item.key + '_total']">
                    <div class="attr">
                        <div>Courriers</div>
                        <div>Déclassés</div>
                        <div>Dématérialisés</div>
                        <div>DGC</div>
                        <br/>
                        <div>Total</div>
                    </div>
                    <div class="val">
                        <div>{{stats[item.key + '_c']}}</div>
                        <div>{{stats[item.key + '_dec']}}</div>
                        <div>{{stats[item.key + '_d']}}</div>
                        <div>{{stats[item.key + '_dgc']}}</div>
                        <br/>
                        <div class="date">
                            <b>{{stats[item.key + '_total']}}</b>
                        </div>
                    </div>
                    <div class="val-date">
                        <div>.</div>
                        <div>.</div>
                        <div>.</div>
                        <div>.</div>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="actions.length > 0">
        <h4>HISTORIQUE & COMMENTAIRES</h4>
        <div class="comment-container">
            <div class="scroll">
                <cdk-virtual-scroll-viewport itemSize="70" class="viewport">
                    <div class="elm" *ngFor="let elm of actions">
                        <div class="name">
                            {{elm.name}}
                            <div class="action">{{elm.act}}</div>
                            <div class="text">{{elm.comment}}</div>
                        </div>
                        <div class="date">
                            {{elm.date_action | date: 'dd/MM/yyyy  HH:mm'}}
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
            <div class="comment">
                <h6>Message</h6>
                <textarea rows="4" cols="40" [(ngModel)]="comment" style="padding: 5px;"></textarea>
                <div class="button-container">
                    <button mat-stroked-button class="btn-next run" (click)="submit(comment)" [disabled]="!comment || sumbitting">
                        Envoyer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
